import { Link } from 'gatsby'
import React from 'react'
import styled from '@emotion/styled'
import theme from '../styles/theme'
import { fluid, cta } from '../styles/mixins'
import { buildImageObj, getBlogUrl } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import ContentBlock from './modules/ContentBlock'
import CTA from '../lib/cta'

function BlogPostPreview (props) {
	const link = props.publishedAt && props.slug && getBlogUrl(props.publishedAt, props.slug.current)
	const internalLink = {
		title: 'Read More',
		href: link,
		target: false
	}

  return (
		<PreviewContainer>
		{props.slug && props.publishedAt &&
			<div>
				<div className="left">
					{props.mainImage && props.mainImage.asset && (
						<img
							src={imageUrlFor(buildImageObj(props.mainImage))
								.width(300)
								.height(Math.floor((16 / 12) * 300))
								.url()}
							alt={props.mainImage.alt}
						/>
					)}
				</div>
				<div className="right">
					<h3 className="blog-title">{props.title}</h3>
					{props._rawExcerpt && (
						<div className="block-content">
							<ContentBlock content={props._rawExcerpt} />
						</div>
					)}
					<CTA data={internalLink} />
				</div>
			</div>
		}
		</PreviewContainer>
  )
}

export default BlogPostPreview

const PreviewContainer = styled.div`
	margin-top:75px;
	margin-bottom:75px;
	>div {
		display:flex;
		align-items:center;
		color:${theme.colors.green};
		width:100%;
		.left {
			margin-left:50px;
			margin-right:50px;
		}
		.blog-title {
			font-family:${theme.fonts.bold};
			text-transform:uppercase;
			${fluid('font-size', 26, 32, 540, 800)}
			margin-bottom:20px;
		}
		.cta {
			${cta}
		}
	}
	@media (${theme.breakpoints.small}) {
		
		>div {
			flex-direction:column;
			.left {
				width:100%;
				margin-bottom:10px;
				img {
					width:100%;
				}
				margin-left:0;
				margin-right:0;
				
			}
			.right {
				margin:0;
			}
		}
	}
	
`