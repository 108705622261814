import React from 'react'
import { graphql } from 'gatsby'
import { mapEdgesToNodes } from '../lib/helpers'
import BlogPostPreviewGrid from '../components/blog-post-preview-grid'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Hero from '../components/modules/Hero'

export const query = graphql`
  query BlogPageQuery {
    posts: allSanityPost(limit: 12, sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            asset {
              _id
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
		page: sanityPage(_id: { regex: "/(drafts.|)blog/" }) {
			_id
      header
      _rawBody
			mainImage {
				crop {
					_key
					_type
					top
					bottom
					left
					right
				}
				hotspot {
					_key
					_type
					x
					y
					height
					width
				}
				asset {
					_id
				}
				alt
			}
			cta {
				title
				href
				target
			}
		}
  }
`

const BlogPage = props => {
  const { data, errors } = props
	const page = (data || {}).page

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const postNodes = data && data.posts && mapEdgesToNodes(data.posts)

  return (
    <Layout>
      <SEO title='Blog' />
      <Container>
				<Hero page={page} />
        {postNodes && postNodes.length > 0 && <BlogPostPreviewGrid nodes={postNodes} />}
      </Container>
    </Layout>
  )
}

export default BlogPage
