import { Link } from 'gatsby'
import React from 'react'
import styled from '@emotion/styled'
import { MaxWidthContainer } from '../styles/mixins'
import BlogPostPreview from './blog-post-preview'

function BlogPostPreviewGrid (props) {
  return (
    <GridContainer>
      {props.title && (
        <h2>
          {props.browseMoreHref ? (
            <Link to={props.browseMoreHref}>{props.title}</Link>
          ) : (
            props.title
          )}
        </h2>
      )}
			<MaxWidthContainer maxW="1100px">
				<ul>
					{props.nodes &&
						props.nodes.map(node => (
							<li key={node.id}>
								<BlogPostPreview {...node} />
							</li>
						))}
				</ul>
			</MaxWidthContainer>
      {/* {props.browseMoreHref && (
        <div >
          <Link to={props.browseMoreHref}>Browse more</Link>
        </div>
      )} */}
    </GridContainer>
  )
}

BlogPostPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default BlogPostPreviewGrid

const GridContainer = styled.div`
	ul {
		list-style:none;
	}
`